import React from 'react';
import styled from 'styled-components';
import Toolbar from './toolbar';

const StyledToolbar = styled(Toolbar)`
  flex-direction: column;

  .toolbar-item {
    width: 78px;
    padding: 13px 16px;
  }
`;

const VerticalToolbar = (props) => {
  return <StyledToolbar {...props} />;
};

VerticalToolbar.displayName = 'VerticalToolbar';

export default VerticalToolbar;
import React from 'react'

function IconButton(props) {
    return (
        <div className=' rounded-full bg-slate-900 mr-2 mt-1'>
            <div className=' p-2 '> 
                {props.children}
            </div>
        </div>
    )
}

export default IconButton
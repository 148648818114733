import React, { memo } from 'react';
import styled from 'styled-components';
// import classnames from 'classnames';
// import { FormattedMessage } from '@kepler.gl/localization';

const StyledDiv = styled.div.attrs(props => ({
//   className: classnames('toolbar-item', props.className),
}))`
  color: ${props => (props.active ? props.theme.toolbarItemIconHover : props.theme.panelHeaderIcon)};
  padding: 12px 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 110px;
  justify-content: space-between;
  border: 1px solid ${props => (props.active ? props.theme.toolbarItemBorderHover : 'transparent')};
  border-radius: ${props => props.theme.toolbarItemBorderRaddius};
  background-color: ${props => (props.active ? props.theme.toolbarItemBgdHover : props.theme.dropdownListBgd)};

  .toolbar-item__svg-container {
    margin-bottom: 4px;
  }
  .toolbar-item__title {
    white-space: nowrap;
    color: ${props => props.theme.textColorHl};
  }

  :hover {
    background-color: ${props => props.theme.toolbarItemBgdHover};
    border-color: ${props => props.theme.toolbarItemBorderHover};
    svg {
      color: ${props => props.theme.toolbarItemIconHover};
    }
    cursor: pointer;
  }
`;

const ToolbarItem = memo(({ id, className, active, onClose, onClick, icon: Icon, label }) => {
  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();

    if (typeof onClose === 'function') {
      onClose();
    }

    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <StyledDiv id={id} className={className} active={active} onClick={handleClick}>
      {Icon && (
        <div className="toolbar-item__svg-container">
          <Icon />
        </div>
      )}
      <div className="toolbar-item__title">
        {/* <FormattedMessage id={label} /> */}
      </div>
    </StyledDiv>
  );
});

export default ToolbarItem;
